import start from './main.js';
import App from './App.vue';
start(App, {
  shapeURL: 'data/world.pbf',
  dataURL: 'data/world_days.pbf',
  title: 'World Countries',
  datasetLabel: 'World',
  center: [11, 48.2324],
  zoom: 1
});
